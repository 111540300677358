import React from 'react'
import {
  Box,
  Button,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {FaMailBulk, FaSlack, FaTv} from "react-icons/fa";

const bull = (<Box
  component="span"
  sx={{display: 'inline-block', mx: '2px', transform: 'scale(0.8)'}}
>
  •
</Box>);

const Card = (props) => {
  const {contact} = props;
  return <React.Fragment>
    <CardContent>
      <List>
        <ListItem>
          <ListItemIcon>
            {contact.icon}
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
              {contact.name}
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
      <Box m={1} p={1}>
        <Typography variant="h5" component="div">
          {contact.desc}
        </Typography>
      </Box>
    </CardContent>
    <CardActions>
      <Box m={1} p={1}>
        {contact.type === "url" && <Button variant={"outlined"} href={contact.value}>Contact</Button>}
        {contact.type === "email" && <Button variant={"outlined"} href={`mailto:${contact.value}`}>Contact</Button>}
      </Box>
    </CardActions>
  </React.Fragment>
};


const GridCard = (props) => {
  return <Grid item xs>
    <Card contact={props.contact}/>
  </Grid>
}

const CONTACTS = [{
  name: "Contact",
  desc: "General inquiries, and communications. I try to respond to every email.",
  value: "contact@andrewbonar.com",
  type: "email",
  icon: <FaMailBulk fontSize={"x-large"}/>,
}, {
  name: "Media Inquiries",
  desc: "For film, tv and media rights inquiries, and communications.",
  value: "media@andrewbonar.com",
  type: "email",
  icon: <FaTv fontSize={"x-large"}/>,
}, {
  name: "Community Chat",
  desc: "Slack channels to chat with others, ask questions to the author, and more.",
  value: "https://join.slack.com/t/slack-uyr7898/shared_invite/zt-1k1n5a5j8-wMSn9kSOGsEjzL7Fg~XpIg",
  type: "url",
  icon: <FaSlack fontSize={"x-large"}/>,
},

]

export class testimonialsComponent extends React.Component {
  render() {
    return (<section className="contact-section section">
      <h2 className="section-title">Contact</h2>
      <Grid container>
        {CONTACTS.map((c) => {
          return <GridCard key={c.name} contact={c}/>
        })}
      </Grid>
    </section>);
  }
}

export default testimonialsComponent;
