import React from 'react';

import {EMAIL} from '../data/project_metadata'

export class emailComponent extends React.Component {
    render() {
        return (
            <a className={this.props.class ? this.props.class : ''}
               href={'mailto:'+EMAIL}>{EMAIL}</a>
        );
    }
}
export default emailComponent;