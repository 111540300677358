import React from 'react';

export class nameComponent extends React.Component {

    render() {
        if(this.props.type === 'href'){
            return <a href="https://andrewBonar.com">Andrew Bonar</a>
        }
        else
        {
            return <span>Andrew Bonar</span>;
        }
    }
}
export default nameComponent;